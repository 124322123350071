<template>
  <modal
    :name="ADD_TREATMENT_REVIEW_MODAL"
    :adaptive="true"
    height="auto"
    :width="720"
    style="z-index: 2000"
    @closed="$emit('close')"
    :styles="{ overflow: 'visible' }"
  >
    <div class="modal-container w-100">
      <div class="px-1 pt-1">
        <p class="f-16 text-black font-600 mb-0">Are you sure you want to add treatment to the booking?</p>
      </div>
      <div class="px-1 pt-1">
        <div
          class="d-flex align-items-center"
          style="
            border-radius: 8px;
            padding: 12px;
            gap: 8px;
            background-color: #FDEEEE;
          "
        >
          <img
            src="/assets/img/icon/warning-icon-red.svg"
            width="20"
            height="20"
            alt=""
          />
          <p class="f-12 font-500 mb-0" style="color: #EB5757;">
            Adding treatment will affect other treatments in your booking. Please confirm to proceed.
          </p>
        </div>
      </div>
      <div class="px-1 pt-1 d-flex align-items-center">
        <p class="f-12 font-600 mb-0" style="margin-right: 12px;">Information:</p>
        <div class="f-12 d-flex align-items-center" style="gap: 8px;">
          <div
            class="information-box"
            style="background-color: #E0F7EE;"
          >
          </div>
          Added
        </div>
        <div class="f-12 d-flex align-items-center" style="gap: 8px; margin-left: 16px;">
          <div
            class="information-box"
            style="background-color: #FFF7EF;"
          >
          </div>
          Impacted
        </div>
      </div>
      <div class="px-1 mt-1 mb-1" style="max-height: 300px; overflow-y: auto;">
        <TableV2>
          <thead>
            <tr>
              <th>Order</th>
              <th>Treatment Detail</th>
              <th>Time</th>
              <th>Room</th>
            </tr>
          </thead>
          <tbody v-if="!previewData.length">
            <tr>
              <td colspan="4" class="text-center">{{ `${isLoading? 'Loading...' : 'No data'}` }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr
              :class="getTableRowClass(bookingDetail.status)"
              v-for="(bookingDetail, bookingDetailIdx) in previewData"
              :key="bookingDetail.id"
            >
              <td class="text-center">
                {{ bookingDetailIdx + 1 }}
              </td>
              <td>
                {{ bookingDetail.treatmentDetailTitle }}
              </td>
              <td>
                {{ bookingDetail.startTime }} - {{ bookingDetail.endTime }}
              </td>
              <td>
                <div :style="`margin-bottom: ${bookingDetail.clinicRoom.conflicted ? '8px' : '0'};`">
                  {{ bookingDetail.clinicRoom.name }} -
                  {{ getRoomTypeLabel(bookingDetail.clinicRoom.type) }}
                </div>
                <a
                  v-if="bookingDetail.clinicRoom.conflicted"
                  target="_blank"
                  :href="`/offline-clinic-booking/${bookingDetail.clinicRoom.conflicted.bookingNumber}`"
                  style="
                    color: #FF7F32 !important;
                    text-decoration: underline;
                  "
                >
                  {{ bookingDetail.clinicRoom.conflicted.description }}
                  <img
                    src="/assets/img/icon/link-icon-orange.svg"
                    width="14"
                    height="14"
                    alt=""
                  />
                </a>
              </td>
            </tr>
          </tbody>
        </TableV2>
      </div>
      <hr class="m-0" />
      <div class="modal-footer">
        <button
          class="btn btn-cancel rounded m-0"
          @click="$emit('close')"
        >
          Cancel
        </button>
        <button
          class="btn btn-add rounded m-0"
          @click="save()"
          :disabled="isLoading || isLoadingAdd || !previewData.length"
        >
          Add this treatment
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
const ADD_TREATMENT_REVIEW_MODAL = 'add-treatment-review-modal';

import TableV2 from '@/components/TableV2';

export default {
  components: {
    TableV2,
  },
  props: {
    isOpen: Boolean,
    bookingNumber: String,
    addedTreatmentBookingDetailId: Number,
  },
  data() {
    return {
      ADD_TREATMENT_REVIEW_MODAL,
      isLoading: true,
      isLoadingAdd: false,
      previewData: [],
    };
  },
  methods: {
    getRoomTypeLabel(type) {
      switch (type) {
        case 'CONSULT':
          return 'Consult Room';
        case 'FACIAL':
          return 'Facial Room';
        case 'TREATMENT':
          return 'Treatment Room';
        case 'LASER':
          return 'Laser Room';
        default:
          return '';
      }
    },
    getTableRowClass(bookingDetailStatus) {
      if (bookingDetailStatus === 'ADDED') {
        return 'tr-bg-green';
      }
      if (bookingDetailStatus === 'IMPACTED') {
        return 'tr-bg-yellow';
      }
      return '';
    },
    getPreview() {
      this.isLoading = true;
      const payload = {
        action: "ADD",
        bookingNumber: this.bookingNumber,
        treatmentId: this.addedTreatmentBookingDetailId,
      };
      this.axios
        .post(
          '/v2/clinical/offline-clinic-booking-detail/modify-preview',
          payload
        )
        .then((res) => {
          this.previewData = res.data.data;
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: err.response.data.message,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    save() {
      this.isLoadingAdd = true;
      const payload = {
        bookingNumber: this.bookingNumber,
        treatmentId: this.addedTreatmentBookingDetailId,
      }
      this.axios
        .post('/v2/clinical/offline-clinic-booking-detail', payload)
        .catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: err.response.data.message,
          });
        })
        .finally(() => {
          this.isLoadingAdd = false;
          this.$emit('close');
        });
    },
  },
  watch: {
    isOpen: function (isOpen) {
      if (isOpen) {
        this.$nextTick(() => {
          this.previewData = [];
          this.getPreview();
        });
        this.$modal.show(ADD_TREATMENT_REVIEW_MODAL);
      } else {
        this.$modal.hide(ADD_TREATMENT_REVIEW_MODAL);
      }
    },
  },
};
</script>

<style scoped>
.modal-container {
  overflow: visible;
  height: 100%;
}

.information-box {
  width: 20px;
  height: 20px;
  border: 0.5px solid #BCC0D1;
}

.tr-bg-green {
  background-color: #E0F7EE;
}

.tr-bg-red {
  background-color: #FDEEEE;
  text-decoration: line-through;
}

.modal-footer {
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: #f2f4f6;
}

.btn-cancel {
  border-radius: 8px;
  border: 1px solid #bcc0d1;
  color: #5b5e62;
  background-color: #ffffff;
}

.btn-add {
  color: #ffffff;
  background-color: #0072CD;
}

.btn-add:disabled {
  background-color: rgba(17, 24, 32, 0.4);
}
</style>
