<template>
  <modal
    :name="EDIT_ROOM_MODAL"
    :adaptive="true"
    height="auto"
    :width="480"
    style="z-index: 2000"
    @closed="$emit('close')"
    :styles="{ overflow: 'visible' }"
  >
    <div class="modal-container w-100">
      <div class="d-flex align-items-center justify-content-between p-1">
        <p class="f-16 text-black font-600 mb-0">Edit Room</p>
        <div
          class="
            d-flex
            align-items-center
            justify-content-center
            rounded-circle
            cursor-pointer
          "
          style="
            width: 24px;
            height: 24px;
            background-color: rgb(228, 232, 237);
          "
          @click="$emit('close')"
        >
          <i class="fa fa-close" style="color: rgb(17, 24, 32)"></i>
        </div>
      </div>
      <hr class="m-0" />
      <div class="p-1">
        <p class="input-label">Room</p>
        <InputSelect
          :options="clinicRoomOptions"
          v-model="value"
          width="100%"
          :searchable="true"
          @search="getRoomOptions"
          :clearable="false"
        >
          <template #option="{ option }">
            <div class="d-flex flex-column">
              <div class="d-flex align-items-center gap-0.5">
                <span class="text-truncate">
                  {{ option.metadata.name }} -
                  {{ getRoomTypeLabel(option.metadata.type) }}
                </span>
                <div v-if="!option.metadata.isAvailable" class="flex-shrink-0">
                  <Badge textColor="#FFFFFF" bgColor="#FFAE62">Used</Badge>
                </div>
              </div>
              <p v-if="option.metadata.description" class="option-description">
                ({{ option.metadata.description }})
              </p>
            </div>
          </template>
        </InputSelect>
      </div>
      <hr class="m-0" />
      <div class="modal-footer">
        <button
          class="btn btn-cancel rounded w-100 m-0"
          @click="$emit('close')"
        >
          Cancel
        </button>
        <button
          class="btn btn-save rounded w-100 m-0"
          @click="save()"
          :disabled="
            value === null ||
            initialValue === null ||
            value.value === initialValue.value
          "
        >
          Save
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import InputSelect from '@/components/InputSelect';
import Badge from '@/components/Badge';

const EDIT_ROOM_MODAL = 'edit-room-modal';

export default {
  components: {
    InputSelect,
    Badge,
  },
  props: {
    isOpen: Boolean,
    bookingDetailId: Number,
    selectedValue: Object,
  },
  data() {
    return {
      EDIT_ROOM_MODAL,
      clinicRoomOptions: [],
      value: this.selectedValue ?? null,
      initialValue: this.selectedValue ?? null,
    };
  },
  methods: {
    getRoomTypeLabel(type) {
      switch (type) {
        case 'CONSULT':
          return 'Consult Room';
        case 'FACIAL':
          return 'Facial Room';
        case 'TREATMENT':
          return 'Treatment Room';
        case 'LASER':
          return 'Laser Room';
        default:
          return '';
      }
    },
    getRoomOptions(search) {
      this.axios
        .get(
          `/v2/clinical/offline-clinic-booking-detail/${this.bookingDetailId}/offline-clinic-rooms`,
          { params: { search: search || undefined } }
        )
        .then((res) => {
          this.clinicRoomOptions = res.data.data.rows.map((item) => ({
            value: item.id,
            label: item.name,
            metadata: item,
          }));
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: err.response.data.message,
          });
        });
    },
    save() {
      this.axios
        .patch(
          `/v2/clinical/offline-clinic-booking-detail/${this.bookingDetailId}`,
          { clinicRoomId: this.value.value }
        )
        .then(() => {
          this.$emit('save');
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: err.response.data.message,
          });
        })
        .finally(() => {
          this.$emit('close');
        });
    },
  },
  watch: {
    isOpen: function (isOpen) {
      if (isOpen) {
        this.clinicRoomOptions = [];
        this.getRoomOptions();
        this.$modal.show(EDIT_ROOM_MODAL);
      } else {
        this.$modal.hide(EDIT_ROOM_MODAL);
      }
    },
    selectedValue: function (newValue) {
      this.value = newValue ?? null;
      this.initialValue = newValue ?? null;
    },
  },
};
</script>

<style scoped>
.gap-0\.5 {
  gap: 0.5rem;
}

.modal-container {
  overflow: visible;
  height: 100%;
}

.input-label {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 4px;
}

.option-description {
  font-size: 12px;
  margin: 0;
  color: #798ea5;
}

.modal-footer {
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: #f2f4f6;
}

.btn-cancel {
  border-radius: 8px;
  border: 1px solid #bcc0d1;
  color: #5b5e62;
  background-color: #ffffff;
}

.btn-save {
  color: #ffffff;
  background-color: #0072cd;
}

.btn-save:disabled {
  background-color: rgba(17, 24, 32, 0.4);
}
</style>
