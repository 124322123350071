<template>
  <div>
    <dropdown-menu
      position="right"
      @open="handleOpenDropdown"
      @close="handleCloseDropdown"
      ref="dropdown"
    >
      <div slot="trigger" class="trigger-button">
        <span>Action</span>
        <span>
          <i class="fa fa-chevron-down" :class="{ 'rotate-180': isOpen }"></i>
        </span>
      </div>
      <div slot="content" class="action-content">
        <div class="item" @click="viewDetails">
          <img
            src="/assets/img/icon/treatment-icon.svg"
            width="24"
            height="24"
            alt=""
            class="flex-shrink-0"
          />
          View Details
        </div>
        <div
          class="item"
          v-if="hasEditPermission && bookingStatus === 'BOOKED'"
          @click="sendReminder"
        >
          <img
            src="/assets/img/icon/ringing-bell-icon.svg"
            width="24"
            height="24"
            alt=""
            class="flex-shrink-0"
          />
          Send Reminder
        </div>
        <div
          class="item"
          v-if="hasEditPermission && bookingStatus === 'BOOKED'"
          @click="confirmBooking"
        >
          <img
            src="/assets/img/icon/tick-icon.svg"
            width="24"
            height="24"
            alt=""
            class="flex-shrink-0"
          />
          Confirm Booking
        </div>
        <div
          class="item"
          v-if="hasEditPermission && bookingStatus === 'ON_TREATMENT'"
          @click="markAsCompleted"
        >
          <img
            src="/assets/img/icon/tick-icon.svg"
            width="24"
            height="24"
            alt=""
            class="flex-shrink-0"
          />
          Mark as Completed
        </div>
        <div
          class="item"
          v-if="hasEditPermission && bookingStatus === 'CONFIRMED'"
          @click="checkIn"
        >
          <img
            src="/assets/img/icon/check-in-icon.svg"
            width="24"
            height="24"
            alt=""
            class="flex-shrink-0"
          />
          Check-in
        </div>
        <template
          v-if="
            hasEditPermission && ['BOOKED', 'CONFIRMED'].includes(bookingStatus)
          "
        >
          <hr style="margin: 4px 0" />
          <div class="item" @click="openCancelReasonModal">
            <img
              src="/assets/img/icon/close-icon.svg"
              width="24"
              height="24"
              alt=""
              class="flex-shrink-0"
            />
            Cancel Booking
          </div>
        </template>
      </div>
    </dropdown-menu>
    <!-- Cancel reason modal -->
    <modal
      :name="`${CANCEL_REASON_MODAL}_${booking.id}`"
      :adaptive="true"
      height="auto"
      :width="480"
      style="z-index: 2000"
    >
      <ValidationObserver ref="modalCancelReasonObserver" slim>
        <div class="modal-container w-100">
          <div class="d-flex align-items-center justify-content-between mb-1">
            <p class="f-16 text-black font-600 mb-0">Cancel Booking</p>
            <div
              class="
                d-flex
                align-items-center
                justify-content-center
                rounded-circle
                cursor-pointer
              "
              style="
                width: 24px;
                height: 24px;
                background-color: rgb(228, 232, 237);
              "
              @click="closeCancelReasonModal()"
            >
              <i class="fa fa-close" style="color: rgb(17, 24, 32)"></i>
            </div>
          </div>
          <ValidationProvider
            name="cancel reason"
            v-slot="{ errors }"
            rules="required"
            slim
          >
            <div
              class="form-group"
              :class="{ 'validation-warning': errors[0] }"
            >
              <textarea
                maxlength="120"
                rows="4"
                class="form-control"
                :class="{ 'validation-warning': errors[0] }"
                placeholder="Write the reason"
                v-model="cancelReason"
                style="resize: none"
              >
              </textarea>
              <div
                class="f-12 d-flex align-items-center justify-content-between"
                style="margin-top: 4px"
              >
                <div class="text-muted" v-if="errors[0]">{{ errors[0] }}</div>
                <div
                  style="flex: 1 0 auto"
                  class="text-right"
                  :class="{ 'text-muted': errors[0] }"
                >
                  {{ cancelReason.length }}/120
                </div>
              </div>
            </div>
          </ValidationProvider>
          <button
            class="btn btn-primary mt-2 rounded w-100"
            @click="cancelBooking()"
          >
            Submit Cancellation
          </button>
        </div>
      </ValidationObserver>
    </modal>

    <!-- Edit user modal -->
    <modal
      :name="`${EDIT_USER_MODAL}_${booking.id}`"
      :adaptive="true"
      height="auto"
      :width="800"
      style="z-index: 2000"
    >
      <div class="modal-container w-100 p-0">
        <p v-if="isUserDataLoading">Loading...</p>
        <UserForm
          v-else
          :userId="booking.user.id"
          :userProps="userData"
          :isLoadingUser="isUserDataLoading"
          pageType="edit-modal"
          saveLabel="Save & Check-in"
          @save="submitCheckIn()"
          @cancel="closeEditUserModal()"
        ></UserForm>
      </div>
    </modal>

    <!-- Complete booking modal -->
    <modal
      :name="`${COMPLETE_BOOKING_MODAL}_${booking.id}`"
      :adaptive="true"
      height="auto"
      :width="480"
      style="z-index: 2000"
    >
      <div class="modal-container w-100">
        <div class="d-flex align-items-center justify-content-center">
          <img
            src="/assets/img/icon/info-circle-icon-blue.svg"
            width="72"
            height="72"
            alt=""
          />
        </div>
        <p class="f-16 font-600 text-center" style="margin: 22px 0 24px 0">
          Are you sure you want to complete this booking?
        </p>
        <div class="d-flex" style="gap: 14px">
          <button
            class="btn btn-outline-blue-secondary rounded"
            @click="closeCompleteBookingModal()"
            style="flex: 1"
          >
            No
          </button>
          <button
            class="btn btn-blue-secondary rounded"
            @click="completeBooking()"
            style="flex: 1"
          >
            Yes
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import DropdownMenu from '@/components/DropdownMenu/DropdownMenu';
import UserForm from './UserForm.vue';
import moment from 'moment';

const CANCEL_REASON_MODAL = 'cancel-reason-modal';
const EDIT_USER_MODAL = 'edit-user-modal';
const COMPLETE_BOOKING_MODAL = 'complete-booking-modal';

export default {
  components: {
    DropdownMenu,
    UserForm,
  },
  props: {
    booking: Object,
    hasEditPermission: Boolean,
  },
  data() {
    return {
      isOpen: false,
      // cancel reason modal
      CANCEL_REASON_MODAL,
      cancelReason: '',
      // edit user modal
      EDIT_USER_MODAL,
      userData: {},
      isUserDataLoading: true,
      // complete booking modal
      COMPLETE_BOOKING_MODAL,
    };
  },
  computed: {
    bookingStatus() {
      return this.booking.status.enum;
    },
  },
  methods: {
    handleOpenDropdown() {
      this.isOpen = true;
    },
    handleCloseDropdown() {
      this.isOpen = false;
    },
    viewDetails() {
      this.$router.push(
        `/offline-clinic-booking/${this.booking.bookingNumber}`
      );
    },
    sendReminder() {
      const { user, clinic, date, startTime, bookingDetails } = this.booking;
      if (!user.mobileNumber) {
        this.$notify({
          type: 'error',
          title: 'Error!',
          text: 'Add customer phone number to send reminder',
        });
        this.$refs.dropdown.closeDropdown();
        return;
      }
      const newLineChar = '%0A';
      const mobileNumber = `62${user.mobileNumber.slice(1)}`;
      const formattedTreatmentDate = moment(date)
        .locale('id')
        .format('D MMMM YYYY');
      const treatmentDateTime = moment(`${date} ${startTime}`);
      const lastConfirmationDateTime = moment(treatmentDateTime).subtract(
        12,
        'hours'
      );
      const formattedLastConfirmationDateTime = moment(lastConfirmationDateTime)
        .locale('id')
        .format('D MMMM YYYY [jam] HH:mm');
      const formattedTreatments = bookingDetails
        .map(
          (bookingDetail) =>
            `${newLineChar} - ${bookingDetail.treatmentDetailTitle}`
        )
        .join('');
      window.open(
        `https://wa.me/${mobileNumber}?text=Hi Kak ${user.name}!${newLineChar}Kami ingin mengingatkan jadwal reservasi di ${clinic.name} pada tgl ${formattedTreatmentDate} jam ${startTime}, berikut ini:${newLineChar}${formattedTreatments}${newLineChar}${newLineChar}Segera lakukan konfirmasi appointment dengan membalas pesan ini. Jika tidak melakukan konfirmasi sampai dengan ${formattedLastConfirmationDateTime}, maka akan dianggap batal/cancel.${newLineChar}Terima kasih${newLineChar}${newLineChar}*Abaikan apabila sudah konfirmasi via aplikasi`
      );
      this.$refs.dropdown.closeDropdown();
    },
    updateStatus({ payload, successMessage, onSuccess }) {
      this.axios
        .patch(
          `/v2/clinical/offline-clinic-booking/${this.booking.bookingNumber}`,
          payload
        )
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Success',
            text: successMessage,
          });
          onSuccess?.();
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error!',
            text: err.response.data.message,
          });
        })
        .finally(() => {
          this.$emit('finishAction');
        });
    },
    confirmBooking() {
      this.updateStatus({
        payload: { status: 'CONFIRMED' },
        successMessage: 'Booking confirmed',
      });
      this.$refs.dropdown.closeDropdown();
    },
    openCancelReasonModal() {
      this.$refs.dropdown.closeDropdown();
      this.cancelReason = '';
      this.$modal.show(`${CANCEL_REASON_MODAL}_${this.booking.id}`);
    },
    closeCancelReasonModal() {
      this.$modal.hide(`${CANCEL_REASON_MODAL}_${this.booking.id}`);
    },
    getUserData(userId) {
      this.axios
        .get(`/v2/clinical/offline-clinic-booking/users/${userId}`)
        .then((res) => {
          this.userData = res.data.data;
          this.isUserDataLoading = false;
        });
    },
    completeBooking() {
      this.updateStatus({
        payload: { status: 'COMPLETED' },
        successMessage: 'Booking completed',
        onSuccess: () => {
          this.viewDetails();
        },
      });
    },
    closeCompleteBookingModal() {
      this.$modal.hide(`${COMPLETE_BOOKING_MODAL}_${this.booking.id}`);
    },
    markAsCompleted() {
      if (
        this.booking.bookingDetails.some(
          (bookingDetail) => bookingDetail.status.enum !== 'DONE'
        )
      ) {
        this.$notify({
          type: 'error',
          title: 'Error!',
          text: 'Make sure all treatments are marked done',
        });
        this.viewDetails();
      } else if (
        this.booking.bookingDetails.some((bookingDetail) =>
          bookingDetail.requiredClinicTeamRoles.every(
            (role) => role.assignedClinicTeam === null
          )
        )
      ) {
        this.$notify({
          type: 'error',
          title: 'Error!',
          text: 'Make sure all treatments are assigned to clinic team',
        });
        this.viewDetails();
      } else {
        this.$modal.show(`${COMPLETE_BOOKING_MODAL}_${this.booking.id}`);
      }
      this.$refs.dropdown.closeDropdown();
    },
    submitCheckIn() {
      this.updateStatus({
        payload: { status: 'CHECKED_IN' },
        successMessage: 'Booking checked-in',
        onSuccess: () => {
          this.viewDetails();
        },
      });
    },
    checkIn() {
      if (this.booking.user.hasTreatmentHistory) {
        this.submitCheckIn();
      } else {
        this.getUserData(this.booking.user.id);
        this.$modal.show(`${EDIT_USER_MODAL}_${this.booking.id}`);
      }

      this.$refs.dropdown.closeDropdown();
    },
    closeEditUserModal() {
      this.$modal.hide(`${EDIT_USER_MODAL}_${this.booking.id}`);
    },
    async cancelBooking() {
      const isValid = await this.$refs.modalCancelReasonObserver.validate();
      if (isValid) {
        this.updateStatus({
          payload: { status: 'CANCELED', cancelReason: this.cancelReason },
          successMessage: 'Booking canceled',
          onSuccess: () => {
            this.closeCancelReasonModal();
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.trigger-button {
  height: 32px;
  padding-inline: 12px;
  background-color: #ffffff;
  border: 1px solid #0072cd;
  color: #0072cd;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.rotate-180 {
  transform: rotate(180deg);
}

.action-content {
  padding: 8px;
}

.item {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 14px;
}

.item:hover {
  border-radius: 8px;
  background-color: #ddedfa;
  cursor: pointer;
}

.modal-container {
  overflow: auto;
  padding: 1rem;
  height: 100%;
}

.btn-blue-secondary {
  border-color: #0072cd;
  background-color: #0072cd;
  color: #ffffff;
}

.btn-outline-blue-secondary {
  border: 1px solid #0072cd;
  color: #0072cd;
}
</style>
