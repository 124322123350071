<template>
  <modal
    :name="EDIT_CLINIC_TEAM_MODAL"
    :adaptive="true"
    height="auto"
    :width="480"
    style="z-index: 2000"
    @closed="close()"
    :styles="{ overflow: 'visible' }"
  >
    <div class="modal-container w-100">
      <div class="d-flex align-items-center justify-content-between px-1 pt-1">
        <p class="f-16 text-black font-600 mb-0">Clinic Team</p>
        <div
          class="
            d-flex
            align-items-center
            justify-content-center
            rounded-circle
            cursor-pointer
          "
          style="
            width: 24px;
            height: 24px;
            background-color: rgb(228, 232, 237);
          "
          @click="close()"
        >
          <i class="fa fa-close" style="color: rgb(17, 24, 32)"></i>
        </div>
      </div>
      <TabsV2
        :tabs="tabs"
        :activeTab="activeTabState"
        @change="handleChangeTab"
        :styleObject="{ paddingInline: '1rem' }"
      ></TabsV2>
      <div class="d-flex flex-column">
        <div class="pt-1 px-1">
          <InputText
            v-model="search"
            @input="handleSearch"
            :placeholder="
              activeTabState ? `Search ${activeTabState.label}` : 'Search'
            "
            iconClass="fa-search"
            :styleObject="{
              width: '100%',
            }"
          />
        </div>
        <div class="options p-1">
          <p class="input-label">
            {{
              activeTabState
                ? `Recommended ${activeTabState.label}`
                : 'Recommended'
            }}
          </p>
          <div
            class="option"
            :class="{ 'option-selected': isOptionSelected(option.id) }"
            v-for="option in clinicTeamOptions"
            :key="option.id"
            @click="handleClickOption(option)"
          >
            <div class="flex-shrink-0">
              <ClinicTeamAvatar
                :clinicTeam="{
                  avatar: option.avatar,
                  status: option.status,
                }"
              ></ClinicTeamAvatar>
            </div>
            <div class="d-flex flex-column">
              <p
                class="m-0 text-black text-truncate"
                v-html="getHighlightedOptionLabel(option.name)"
              />
              <p v-if="option.description" class="option-description">
                ({{ option.description }})
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr class="m-0" />
      <div class="modal-footer">
        <button class="btn btn-cancel rounded w-100 m-0" @click="close()">
          Cancel
        </button>
        <button
          class="btn btn-save rounded w-100 m-0"
          @click="save()"
          :disabled="changedValues.length === 0"
        >
          Save
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import TabsV2 from '@/components/TabsV2';
import InputText from '@/components/InputText';
import ClinicTeamAvatar from './ClinicTeamAvatar.vue';
import { debounce } from 'debounce';

const EDIT_CLINIC_TEAM_MODAL = 'edit-clinic-team-modal';

export default {
  components: {
    TabsV2,
    InputText,
    ClinicTeamAvatar,
  },
  props: {
    isOpen: Boolean,
    bookingDetailId: Number,
    selectedValues: Array,
    activeTab: Object,
  },
  data() {
    return {
      EDIT_CLINIC_TEAM_MODAL,
      activeTabState: this.activeTab ?? null,
      search: '',
      clinicTeamOptions: [],
      values:
        this.selectedValues?.map((role) => ({
          roleSlug: role.slug,
          assignedClinicTeamId: role.assignedClinicTeam
            ? role.assignedClinicTeam.id
            : null,
        })) ?? [],
      initialValues:
        this.selectedValues?.map((role) => ({
          roleSlug: role.slug,
          assignedClinicTeamId: role.assignedClinicTeam
            ? role.assignedClinicTeam.id
            : null,
        })) ?? [],
    };
  },
  computed: {
    tabs() {
      return this.selectedValues.map((item) => ({
        value: item.slug,
        label: item.name,
      }));
    },
    changedValues() {
      const results = [];
      this.values.forEach((value, index) => {
        if (
          value.assignedClinicTeamId !==
          this.initialValues[index].assignedClinicTeamId
        ) {
          results.push(value);
        }
      });

      return results;
    },
  },
  methods: {
    handleChangeTab(tab) {
      this.activeTabState = tab;
      this.search = '';
      this.getClinicTeamOptions();
    },
    handleSearch: debounce(function (search) {
      this.getClinicTeamOptions(search);
    }, 500),
    getClinicTeamOptions(search) {
      this.axios
        .get(
          `/v2/clinical/offline-clinic-booking-detail/${this.bookingDetailId}/offline-clinic-teams`,
          {
            params: {
              search: search || undefined,
              clinicRoleSlug: this.activeTabState?.value,
            },
          }
        )
        .then((res) => {
          this.clinicTeamOptions = res.data.data.rows;
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: err.response.data.message,
          });
        });
    },
    isOptionSelected(optionId) {
      const roleSlug = this.activeTabState?.value;
      const value = this.values.find((value) => value.roleSlug === roleSlug);
      if (value) {
        return value.assignedClinicTeamId === optionId;
      }

      return false;
    },
    getHighlightedOptionLabel(label) {
      if (this.search.length === 0) {
        return label;
      }

      const sanitizedSearch = this.search.replace(
        /[.*+?^${}()|[\]\\]/g,
        '\\$&'
      );
      const regex = new RegExp(`(${sanitizedSearch})`, 'gi');

      return label.replace(regex, `<span style="color: #0072cd;">$1</span>`);
    },
    handleClickOption(option) {
      const roleSlug = this.activeTabState?.value;
      const valueIndex = this.values.findIndex(
        (value) => value.roleSlug === roleSlug
      );
      if (valueIndex !== -1) {
        this.values[valueIndex].assignedClinicTeamId = option.id;
        this.$forceUpdate();
      }
    },
    close() {
      this.search = '';
      this.$emit('close');
    },
    save() {
      const payload = this.changedValues.reduce((acc, curr) => {
        acc[`${curr.roleSlug}TeamId`] = curr.assignedClinicTeamId;
        return acc;
      }, {});
      this.axios
        .patch(
          `/v2/clinical/offline-clinic-booking-detail/${this.bookingDetailId}`,
          payload
        )
        .then(() => {
          this.$emit('save');
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: err.response.data.message,
          });
        })
        .finally(() => {
          this.close();
        });
    },
  },
  watch: {
    isOpen: function (isOpen) {
      if (isOpen) {
        this.$nextTick(() => {
          this.clinicTeamOptions = [];
          this.getClinicTeamOptions();
        });
        this.$modal.show(EDIT_CLINIC_TEAM_MODAL);
      } else {
        this.$modal.hide(EDIT_CLINIC_TEAM_MODAL);
      }
    },
    selectedValues: function (selectedValues) {
      this.values =
        selectedValues?.map((role) => ({
          roleSlug: role.slug,
          assignedClinicTeamId: role.assignedClinicTeam
            ? role.assignedClinicTeam.id
            : null,
        })) ?? [];
      this.initialValues =
        selectedValues?.map((role) => ({
          roleSlug: role.slug,
          assignedClinicTeamId: role.assignedClinicTeam
            ? role.assignedClinicTeam.id
            : null,
        })) ?? [];
    },
    activeTab: function (tab) {
      this.activeTabState = tab;
    },
  },
};
</script>

<style scoped>
.gap-1 {
  gap: 1rem;
}

.modal-container {
  overflow: visible;
  height: 100%;
}

.input-label {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 4px;
}

.options {
  max-height: 320px;
  overflow: auto;
}

.option {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  cursor: pointer;
  border: 1px solid transparent;
}

.option:hover:not(.option-selected) {
  background-color: #f2f4f6;
  border: 1px solid #f2f4f6;
  border-radius: 8px;
}

.option-selected {
  background-color: #ddedfa;
  border: 1px solid #0072cd;
  border-radius: 8px;
}

.option-description {
  font-size: 12px;
  margin: 0;
  color: #798ea5;
}

.modal-footer {
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: #f2f4f6;
}

.btn-cancel {
  border-radius: 8px;
  border: 1px solid #bcc0d1;
  color: #5b5e62;
  background-color: #ffffff;
}

.btn-save {
  color: #ffffff;
  background-color: #0072cd;
}

.btn-save:disabled {
  background-color: rgba(17, 24, 32, 0.4);
}
</style>
