<template>
    <ValidationObserver ref="observer" slim>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <p class="f-16 font-600">Basic Information</p>
                        <hr class="mb-0">
                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <ValidationProvider name="first name" v-slot="{ errors }" rules="required" slim>
                                    <div class="mt-1 d-flex flex-column gap-0.25">
                                        <p class="mb-0 f-12 font-600">First Name <span class="text-danger">*</span></p>
                                        <InputText
                                            v-model="user.firstName"
                                            placeholder="Input First Name"
                                            iconClass="fa-user"
                                        />
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <div class="mt-1 d-flex flex-column gap-0.25">
                                    <p class="mb-0 f-12 font-600">Last Name</p>
                                    <InputText
                                        v-model="user.lastName"
                                        placeholder="Input Last Name"
                                        iconClass="fa-user"
                                    />
                                </div>
                                <ValidationProvider name="birth date" v-slot="{ errors }" rules="required" slim>
                                    <div class="mt-1 d-flex flex-column gap-0.25">
                                        <p class="mb-0 f-12 font-600">Birth Date <span class="text-danger">*</span></p>
                                        <date-picker
                                            v-model="user.dateOfBirth"
                                            format="DD/MM/YYYY"
                                            type="date"
                                            input-class="form-control form-control-default"
                                            value-type="YYYY-MM-DD"
                                            placeholder="Select Birth Date"
                                            style="width: 100%"
                                            :disabled-date="disabledDate"
                                            :default-value="$moment().subtract(17, 'years')"
                                        />
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-lg-6">
                                <div class="mt-1 d-flex flex-column gap-0.25">
                                    <p class="mb-0 f-12 font-600">Gender <span class="text-danger">*</span></p>
                                    <div class="d-flex gap-1">
                                        <button 
                                            type="button" 
                                            class="btn-gender" 
                                            :class="(user && user.gender === 'M') ? 'active' : ''"
                                            @click="setGender('M')"
                                        >
                                            <div class="d-flex align-items-center gap-0.25">
                                                <img
                                                    src="/assets/img/icon/male-gender-icon.svg"
                                                    width="20"
                                                    height="20"
                                                    alt=""
                                                />
                                                <span>Male</span>
                                            </div>
                                        </button>
                                        <button 
                                            type="button" 
                                            class="btn-gender" 
                                            :class="(user && user.gender === 'F') ? 'active' : ''"
                                            @click="setGender('F')"
                                        >
                                            <div class="d-flex align-items-center gap-0.25">
                                                <img
                                                    src="/assets/img/icon/female-gender-icon.svg"
                                                    width="20"
                                                    height="20"
                                                    alt=""
                                                />
                                                <span>Female</span>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                                <ValidationProvider
                                    name="identity card"
                                    v-slot="{ errors }"                    
                                    rules="numeric_only|max:20"
                                    slim
                                >
                                    <div class="mt-1 d-flex flex-column gap-0.25">
                                        <p class="mb-0 f-12 font-600">Identity Card</p>
                                        <InputText
                                            v-model="user.idCard"
                                            :numberOnly="true"
                                            placeholder="Input Identity Card"
                                        />
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <div class="mt-1 d-flex flex-column gap-0.25">
                                    <p class="mb-0 f-12 font-600">Omnicare EMR</p>
                                    <InputText
                                        v-model="user.omnicarePatientId"
                                        placeholder="Input Omnicare EMR"
                                    />
                                </div>
                            </div>
                        </div>
                        <template v-if="user && user.gender === 'F'">
                            <hr style="margin-top: 20px;">
                            <p class="mb-0 f-12 font-600">
                                Are you in the following condition?
                            </p>
                            <div class="d-flex align-items-center flex-wrap gap-1.5" style="margin-top: 4px;">
                                <label class="f-14 d-flex align-items-center gap-0.5">
                                    <input type="checkbox" v-model="user.pregnant">
                                    Pregnant
                                </label>
                                <label class="f-14 d-flex align-items-center gap-0.5">
                                    <input type="checkbox" v-model="user.breastFeeding">
                                    Breastfeeding
                                </label>
                                <label class="f-14 d-flex align-items-center gap-0.5">
                                    <input type="checkbox" v-model="user.promil">
                                    Pregnancy Program
                                </label>
                                <label class="f-14 d-flex align-items-center gap-0.5">
                                    <input type="checkbox" v-model="user.kb">
                                    Using Birth Control
                                </label>
                                <label class="f-14 d-flex align-items-center gap-0.5">
                                    <input type="checkbox" v-model="user.preferFemaleDoctor">
                                    Wearing Hijab
                                </label>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <p class="f-16 font-600">Contact Information</p>
                        <hr class="mb-0">
                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <ValidationProvider
                                    name="phone number"
                                    v-slot="{ errors }"             
                                    :rules="{ required: true, regex: /^08[1-9][0-9]{7,11}$/ }"
                                    slim
                                >
                                    <div class="mt-1 d-flex flex-column gap-0.25">
                                        <p class="mb-0 f-12 font-600">Phone Number <span class="text-danger">*</span></p>
                                        <InputText
                                            v-model="user.mobileNumber"
                                            :numberOnly="true"
                                            placeholder="Input Phone Number"
                                        />
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-lg-6">
                                <ValidationProvider name="email" v-slot="{ errors }" rules="required|email" slim>
                                    <div class="mt-1 d-flex flex-column gap-0.25">
                                        <p class="mb-0 f-12 font-600">Email <span class="text-danger">*</span></p>
                                        <InputText
                                            v-model="user.email"
                                            placeholder="Input Email"
                                            :disabled="['edit','edit-modal'].includes(pageType)"
                                        />
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body f-14">
                        <div class="d-flex justify-content-between align-items-center">
                            <p class="f-16 font-600 mb-0" style="flex: 1;">Address</p>
                            <div
                                class="f-14 cursor-pointer"
                                style="color: #0072CD; flex: 0 0 auto;"
                                @click="resetAddress"
                            >
                                Clear Address
                            </div>
                        </div>
                        <hr class="mb-0">
                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <div class="mt-1 d-flex flex-column gap-0.25">
                                    <p class="mb-0 f-12 font-600">Province</p>
                                    <InputSelect
                                        v-model="user.address.province"
                                        :options="searchOptions.province || []"
                                        placeholder="Select Province"
                                        width="100%"
                                        :searchable="true"
                                        :clearable="false"
                                        @input="(newValue) => onSelectAddress({ ...newValue }, 'province')"
                                        @search="searchProvince"
                                    >
                                    </InputSelect>
                                </div>
                                <ValidationProvider
                                    name="city"
                                    v-slot="{ errors }"
                                    :rules="{ required: user.address.province !== null }"
                                    slim
                                >
                                    <div class="mt-1 d-flex flex-column gap-0.25">
                                        <p class="mb-0 f-12 font-600">City</p>
                                        <InputSelect
                                            v-model="user.address.city"
                                            :options="searchOptions.city || []"
                                            placeholder="Select City"
                                            width="100%"
                                            :searchable="true"
                                            :clearable="false"
                                            :disabled="!user.address.province || !options.city"
                                            @input="(newValue) => onSelectAddress({ ...newValue }, 'city')"
                                            @search="searchCity"
                                        >
                                        </InputSelect>
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider
                                    name="district"
                                    v-slot="{ errors }"
                                    :rules="{ required: user.address.province !== null }"
                                    slim
                                >
                                    <div class="mt-1 d-flex flex-column gap-0.25">
                                        <p class="mb-0 f-12 font-600">District</p>
                                        <InputSelect
                                            v-model="user.address.suburb"
                                            :options="searchOptions.suburb || []"
                                            placeholder="Select District"
                                            width="100%"
                                            :searchable="true"
                                            :clearable="false"
                                            :disabled="!user.address.city || !options.suburb"
                                            @input="(newValue) => onSelectAddress({ ...newValue }, 'suburb')"
                                            @search="searchSuburb"
                                        >
                                        </InputSelect>
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider
                                    name="village"
                                    v-slot="{ errors }"
                                    :rules="{ required: user.address.province !== null }"
                                    slim
                                >
                                    <div class="mt-1 d-flex flex-column gap-0.25">
                                        <p class="mb-0 f-12 font-600">Village, Postal Code</p>
                                        <InputSelect
                                            v-model="user.address.area"
                                            :options="searchOptions.area || []"
                                            placeholder="Select Village and Postal Code"
                                            width="100%"
                                            :searchable="true"
                                            :clearable="false"
                                            :disabled="!user.address.suburb || !options.area"
                                            @input="(newValue) => onSelectAddress({ ...newValue }, 'area')"
                                            @search="searchArea"
                                            :customLabel="({ label, postcode }) => `${label}, ${postcode}`"
                                        >
                                            <template #option="{ option }">
                                                <span class="text-truncate">
                                                    {{ `${option.label}, ${option.postcode}` }}
                                                </span>
                                            </template>
                                        </InputSelect>
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-lg-6">
                                <ValidationProvider
                                    name="address detail"
                                    v-slot="{ errors }"                    
                                    rules="address_symbols|max:200"
                                    slim
                                >
                                    <div class="mt-1 d-flex flex-column gap-0.25">
                                        <p class="mb-0 f-12 font-600">Address Detail</p>
                                        <textarea
                                            cols="30"
                                            rows="3"
                                            class="form-control"
                                            placeholder="Input Address Detail (e.g: Building Name / Street / Number / Block)"
                                            v-model="user.address.address"
                                            style="
                                                resize: none;
                                                min-height: 90px;
                                                max-height: 90px;
                                                border-radius: 8px;
                                                padding: 8px 12px;
                                            "
                                        >
                                        </textarea>
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider
                                    name="address note"
                                    v-slot="{ errors }"     
                                    rules="address_symbols|max:45"
                                    slim
                                >
                                    <div class="mt-1 d-flex flex-column gap-0.25">
                                        <p class="mb-0 f-12 font-600">Address Note</p>
                                        <InputText
                                            v-model="user.address.notes"
                                            placeholder="Input Address Note (e.g: Pagar Hitam)"
                                        />
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="btn-container"
                    :style="`
                        padding: ${pageType === 'edit-modal' ? '21px' : '0'};
                        background-color: ${pageType === 'edit-modal' ? '#f2f4f6' : 'transparent'};
                    `"
                >
                    <button class="btn btn-cancel rounded m-0" @click="$emit('cancel')">
                        Cancel
                    </button>
                    <button class="btn btn-save rounded m-0" :disabled="isLoading" @click="onSubmit()">
                        <div v-if="isLoading">Saving <i class="fa fa-spinner fa-spin"></i></div>
                        <div v-else>{{ saveLabel ||  'Save' }}</div>
                    </button>
                </div>
            </div>
        </div>
    </ValidationObserver>
</template>

<script>
    import InputText from '@/components/InputText';
    import InputSelect from '@/components/InputSelect';

    export default {
        components: {
            InputText,
            InputSelect,
        },
        props: {
            userId: Number,
            userProps: {
                type: Object,
                default: undefined,
            },
            pageType: String, // add, edit
            saveLabel: String,
        },
        data() {
            return {
                user: {
                    email: this.userProps?.email ?? "",
                    firstName: this.userProps?.firstName ?? "",
                    lastName: this.userProps?.lastName ?? "",
                    mobileNumber: this.userProps?.mobileNumber ?? "",
                    dateOfBirth: this.userProps?.dateOfBirth ?? "",
                    gender: this.userProps?.gender ?? "M",
                    pregnant: this.userProps?.pregnant ?? false,
                    breastFeeding: this.userProps?.breastFeeding ?? false,
                    promil: this.userProps?.promil ?? false,
                    kb: this.userProps?.kb ?? false,
                    preferFemaleDoctor: this.userProps?.preferFemaleDoctor ?? false,
                    idCard: this.userProps?.idCard ?? '',
                    omnicarePatientId: this.userProps?.omnicarePatientId ?? '',
                    address: {
                        province: this.userProps?.address?.provinceId
                            ? { value: parseInt(this.userProps.address.provinceId), label: this.userProps.address.province } : null,
                        city: this.userProps?.address?.cityId
                            ? { value: parseInt(this.userProps.address.cityId), label: this.userProps.address.city } : null,
                        suburb: this.userProps?.address?.suburbId
                            ? { value: parseInt(this.userProps.address.suburbId), label: this.userProps.address.suburb } : null,
                        area: this.userProps?.address?.areaId
                            ? {
                                value: parseInt(this.userProps.address.areaId),
                                label: this.userProps.address.area,
                                postcode: this.userProps.address.postalCode
                            } : null,
                        address: this.userProps?.address?.address ?? null,
                        notes: this.userProps?.address?.notes ?? null,
                    },
                },
                options: {
                    province: [],
                },
                searchOptions: {
                    province: [],
                },
                isLoading: false,
            }
        },
        mounted() {
            if (
                this?.userProps?.address?.provinceId
                && this?.userProps?.address?.cityId
                && this?.userProps?.address?.suburbId
            ) {
                Promise.all([
                    this.getProvinces(),
                    this.getCities(this.userProps.address.provinceId),
                    this.getSuburbs(this.userProps.address.cityId),
                    this.getPostalCodes(this.userProps.address.suburbId),
                ]);
            } else {
                this.getProvinces();
            }
        },
        methods: {
            setGender(gender){
                this.user.gender = gender;
            },
            disabledDate(date) {
                return (
                    this.$moment()
                    && date.setHours(0, 0, 0, 0) > new Date(this.$moment().subtract(17, 'years')).setHours(0, 0, 0, 0)
                );
            },
            getProvinces() {
                this.axios
                    .get('/misc/provinces')
                    .then((res) => {
                        const province = res.data.data.map((province) => ({
                            value: province.id,
                            label: province.name,
                        }));
                        this.options.province = province;
                        this.searchOptions.province = province;
                    })
            },
            getCities(provinceId) {
                this.axios
                    .get(`/misc/cities?provinceId=${provinceId}`)
                    .then((res) => {
                        const city = res.data.data.map((city) => ({
                            value: city.id,
                            label: city.name,
                        }))
                        this.options = {
                            ...this.options,
                            city,
                        };
                        this.searchOptions = {
                            ...this.searchOptions,
                            city,
                        };
                    })
            },
            getSuburbs(cityId) {
                this.axios
                    .get(`/misc/suburbs?cityId=${cityId}`)
                    .then((res) => {
                        const suburb = res.data.data.map((suburb) => ({
                            value: suburb.id,
                            label: suburb.name,
                        }))
                        this.options = {
                            ...this.options,
                            suburb,
                        };
                        this.searchOptions = {
                            ...this.searchOptions,
                            suburb,
                        };
                    })
            },
            getPostalCodes(suburbId) {
                this.axios
                    .get(`/misc/postalcodes?suburbId=${suburbId}`)
                    .then((res) => {
                        const area = res.data.data.map((area) => ({
                            value: area.id,
                            label: area.name,
                            postcode: area.postcode,
                        }))
                        this.options = {
                            ...this.options,
                            area,
                        };
                        this.searchOptions = {
                            ...this.searchOptions,
                            area,
                        };
                    })
            },
            async onSelectAddress(newValue, dependKey) {
                const fetchList = {
                    city: this.getCities,
                    suburb: this.getSuburbs,
                    area: this.getPostalCodes,
                }

                let dependedFields = [];
                switch (dependKey) {
                    case "province":
                        dependedFields = ["city", "suburb", "area"];
                        break;
                    case "city":
                        dependedFields = ["suburb", "area"];
                        break;
                    case "suburb":
                        dependedFields = ["area"];
                        break;
                    default:
                        dependedFields = [];
                        break;
                }

                for (let field of dependedFields) {
                    // reset depended fields value and options
                    this.user.address[field] = null;
                    delete this.options[field];
                    delete this.searchOptions[field];
                }

                // refetch the very first depended fields
                // to get options based on new selected depend key
                if (dependedFields[0]) {
                    const { value: newValueValue, label: newValueLabel } = newValue ? newValue : { value: "", label: "" };
                    if (newValueValue && newValueLabel) fetchList[dependedFields[0]](newValueValue);
                }
            },
            resetAddress() {
                this.user.address = {
                    province: null,
                    city: null,
                    suburb: null,
                    area: null,
                    address: null,
                    notes: null,
                };
            },
            async onSubmit() {
                const isValid = await this.$refs.observer.validate();
                if (isValid) {
                    const payload = {
                        ...this.user,
                        dateOfBirth: this.$moment(this.user.dateOfBirth).format('YYYY-MM-DD'),
                        address: {
                            province: this.user.address.province?.label ?? null,
                            provinceId: this.user.address.province?.value ?? null, 
                            city: this.user.address.city?.label ?? null,
                            cityId: this.user.address.city?.value ?? null,
                            suburb: this.user.address.suburb?.label ?? null,
                            suburbId: this.user.address.suburb?.value ?? null,
                            area: this.user.address.area?.label ?? null,
                            areaId: this.user.address.area?.value ?? null,
                            postalCode: this.user.address.area?.postcode ?? null,
                            address: this.user.address.address,
                            notes: this.user.address.notes,
                        },
                    };
                    this.isLoading = true;
                    if (this.pageType === 'add') {
                        this.axios
                            .post('/v2/clinical/offline-clinic-booking/users', { ...payload })
                            .then((res) => {
                                this.$notify({
                                    type: 'success',
                                    title: 'Success',
                                    text: 'User created',
                                });
                                this.$emit('save', res.data.data.id);
                            })
                            .catch((err) => {
                                this.$notify({
                                    type: 'error',
                                    title: 'Error!',
                                    text: err.response.data.message,
                                });
                            })
                            .finally(() => {
                                this.isLoading = false;
                            });
                    } else {
                        this.axios
                            .put(`/v2/clinical/offline-clinic-booking/users/${this.userId}`, { ...payload })
                            .then(() => {
                                this.$notify({
                                    type: 'success',
                                    title: 'Success',
                                    text: 'User updated',
                                });
                                this.$emit('save');
                            })
                            .catch((err) => {
                                this.$notify({
                                    type: 'error',
                                    title: 'Error!',
                                    text: err.response.data.message,
                                });
                            })
                            .finally(() => {
                                this.isLoading = false;
                            });
                    }
                }
            },
            searchProvince(search) {
                this.searchOptions.province = this.options.province.filter((province) =>
                    province.label.toLowerCase().includes(search.toLowerCase())
                );
            },
            searchCity(search) {
                this.searchOptions = {
                    ...this.searchOptions,
                    city: this.options.city?.filter((city) =>
                        city.label.toLowerCase().includes(search.toLowerCase())
                    ) ?? [],
                };
            },
            searchSuburb(search) {
                this.searchOptions = {
                    ...this.searchOptions,
                    suburb: this.options.suburb?.filter((suburb) =>
                        suburb.label.toLowerCase().includes(search.toLowerCase())
                    ) ?? [],
                };
            },
            searchArea(search) {
                this.searchOptions = {
                    ...this.searchOptions,
                    area: this.options.area?.filter((area) =>
                        area.label.toLowerCase().includes(search.toLowerCase())
                    ) ?? [],
                };
            }
        },
    }
</script>

<style>
    .gap-0\.25 {
        gap: 0.25rem;
    }

    .gap-0\.5 {
        gap: 0.5rem;
    }

    .gap-1 {
        gap: 1rem;
    }

    .gap-1\.5 {
        gap: 1.5rem;
    }

    .form-control-default {
        width: 100%;
        border: 1px solid #bcc0d1 !important;
        border-radius: 8px;
        height: 40px;
        display: flex;
        align-items: center;
    }

    .btn-gender {
        width: 100%;
        height: 40px;
        border: 1px solid #bcc0d1;
        background-color: #fff;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        padding-inline: 12px;
    }

    .btn-gender.active {
        border-color: #0072CD;
        background-color: #DDEDFA;
    }

    .btn-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 1rem;
    }

    .btn-cancel {
        border-radius: 8px;
        border: 1px solid #bcc0d1;
        color: #5b5e62;
        background-color: #ffffff;
    }

    .btn-save {
        color: #ffffff;
        background-color: #0072cd;
        padding: 12.6px 48px;
    }

    .btn-save:hover {
        color: #ffffff;
    }
</style>