<template>
    <div class="mw-100 p-2">
        <div class="section">
            <UserForm pageType="add" @save="save" @cancel="cancel" />
        </div>
    </div>
</template>

<script>
    import UserForm from './components/UserForm.vue'

    export default {
        components: {
            UserForm
        },
        mounted() {
            if (!this.hasAddPermission) {
                window.location.assign('/offline-clinic-booking');
            }
        },
        computed: {
            permissions() {
                return this.$store.getters.permissions;
            },
            hasAddPermission() {
                return this.permissions.includes('offline_clinic_booking:add');
            },
        },
        methods: {
            save(userId) {
                this.$router.push(`/offline-clinic-booking/user/${userId}`);
            },
            cancel() {
                this.$router.push('/offline-clinic-booking/user');
            }
        }
    }
</script>