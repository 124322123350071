<template>
  <div
    class="wrapper"
    :class="{ 'disabled': disabled }"
    :style="styleObject"
  >
    <span v-if="iconClass">
      <i class="fa" :class="{ [iconClass]: true }"></i>
    </span>
    <input
      type="text"
      :value="value"
      @input="handleInput"
      :placeholder="placeholder"
      class="input"
      :disabled="disabled"
    />
  </div>
</template>

<script>
export default {
  props: {
    iconClass: {
      type: String,
    },
    styleObject: {
      type: Object,
    },
    value: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    numberOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    handleInput(event) {
      let value = event.target.value;
      if (this.numberOnly) {
        value = value.replace(/\D/g, '');
        event.target.value = value; // set the value back to prevent the UI from temporarily showing non-numeric characters
      }
      this.$emit('input', value);
    },
  },
};
</script>

<style scoped>
.wrapper {
  width: 100%;
  height: 40px;
  border: 1px solid #bcc0d1 !important;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding-inline: 12px;
}

.wrapper.disabled {
  background-color: #f5f5f5;
}

.input {
  border: none;
  outline: none;
  flex: 1;
  font-size: 14px;
  padding: 0;
  height: 100%;
}

.input:disabled {
  cursor: not-allowed;
  background-color: transparent;
}
</style>
